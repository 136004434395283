import React from 'react';
import { Button, Callout, Card, H5, Intent, Spinner } from '@blueprintjs/core';
import { AnnotationBase, Task as ITask } from '@wavely/annotator-sdk';
import { useKeycloak } from '@react-keycloak/web';

import './Annotator.scss';

import annotatorApiClient from '../utils/api';
import Annotations from '../components/Annotations';
import TaskMetadata from '../components/TaskMetadata';
import Rules from '../components/Rules';

const Annotator = () => {
  const keycloak = useKeycloak();
  const taskStartTimeRef = React.useRef<Date>(new Date());

  const [task, setTask] = React.useState<ITask | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(true);

  const fetchTask = React.useCallback(() => {
    setLoading(true);
    annotatorApiClient
      .listTasks({
        headers: { Authorization: `Bearer ${keycloak.keycloak.token}` },
      })
      .then(res => {
        setLoading(false);
        setTask(res.data[0]);
        taskStartTimeRef.current = new Date();
      });
  }, [setTask, setLoading, keycloak.keycloak.token]);

  const validateTask = (annotations: Array<AnnotationBase>) => {
    // Valid task should have a at least one annotation
    return annotations.length > 0;
  };

  const submitTask = React.useCallback(
    (annotations: Array<AnnotationBase>) => {
      // Stop if invalid submission
      if (!task || !validateTask(annotations)) {
        return;
      }

      annotatorApiClient
        .createTaskResult(
          {
            metadata: task.metadata.id!,
            startTime: taskStartTimeRef.current,
            endTime: new Date(),
            annotations,
          },
          { headers: { Authorization: `Bearer ${keycloak.keycloak.token}` } },
        )
        .then(() => fetchTask());
    },
    [task, fetchTask, keycloak.keycloak.token],
  );

  const skipTask = React.useCallback(() => {
    if (!task) {
      return;
    }

    annotatorApiClient
      .createTaskResult(
        {
          metadata: task.metadata.id!,
          startTime: taskStartTimeRef.current,
          endTime: new Date(),
          annotations: [],
        },
        { headers: { Authorization: `Bearer ${keycloak.keycloak.token}` } },
      )
      .then(() => fetchTask());
  }, [task, fetchTask, keycloak.keycloak.token]);

  React.useEffect(fetchTask, []);

  if (isLoading) {
    return <Spinner intent={Intent.PRIMARY} size={25} />;
  }

  if (!task) {
    return (
      <Card>
        <Callout intent={Intent.SUCCESS} title="All done !">
          All tasks completed. Thank you !
        </Callout>
      </Card>
    );
  }

  return (
    <div className="annotator-container">
      <div className="annotator-top-level">
        <TaskMetadata metadata={task.metadata} />
        <Card>
          <H5>Controls</H5>
          <Button
            type="button"
            intent={Intent.NONE}
            icon="chevron-forward"
            text="Skip Task"
            onClick={skipTask}
          />
        </Card>
      </div>
      <Annotations task={task} onAnnotationsCompletion={submitTask} />
      <Rules />
    </div>
  );
};

export default Annotator;
