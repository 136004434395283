import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import './App.scss';

import Annotator from './pages/Annotator';
import Leaderboard from './pages/Leaderboard';

const App: React.FC = () => {
  const keycloak: any = useKeycloak();

  return (
    <main className="app">
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            keycloak.keycloak.authenticated ? <Annotator /> : null
          }
        />
        <Route
          exact
          path="/leaderboard"
          render={() =>
            keycloak.keycloak.authenticated ? <Leaderboard /> : null
          }
        />
      </Switch>
    </main>
  );
};

export default App;
