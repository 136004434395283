import React from 'react';
import { Button, H4, Navbar } from '@blueprintjs/core';
import { Link, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import LoggedUser from './LoggedUser';

const NavBar = () => {
  const keycloak = useKeycloak();
  const location = useLocation();

  return (
    <Navbar fixedToTop>
      <Navbar.Group align="left">
        <Navbar.Heading className="annotator-heading">
          <H4>Annotator</H4>
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align="right">
        {keycloak.keycloak.authenticated ? (
          <>
            {location.pathname === '/leaderboard' ? (
              <Link to="/">
                <Button minimal icon="arrow-left">
                  Go back
                </Button>
              </Link>
            ) : (
              <Link to="/leaderboard">
                <Button minimal icon="th">
                  Leaderboard
                </Button>
              </Link>
            )}
            <LoggedUser
              username={
                keycloak.keycloak.tokenParsed
                  ? (keycloak.keycloak.tokenParsed as any).preferred_username
                  : null
              }
            />
          </>
        ) : null}
      </Navbar.Group>
    </Navbar>
  );
};

export default NavBar;
