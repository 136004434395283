import React from 'react';
import { Score } from '@wavely/annotator-sdk';
import { Card, H5, HTMLTable, Intent, Spinner, Icon } from '@blueprintjs/core';
import { useKeycloak } from '@react-keycloak/web';

import annotatorApiClient from '../utils/api';

import './Leaderboard.scss';

const Leaderboard = () => {
  const keycloak = useKeycloak();
  const [scores, setScores] = React.useState<Array<Score>>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (keycloak.keycloak.authenticated) {
      annotatorApiClient
        .listScores({
          headers: { Authorization: `Bearer ${keycloak.keycloak.token}` },
        })
        .then(res => {
          setScores(res.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [
    setScores,
    setLoading,
    keycloak.keycloak.authenticated,
    keycloak.keycloak.token,
  ]);

  if (isLoading) {
    return <Spinner intent={Intent.PRIMARY} size={25} />;
  }

  return (
    <Card className="leaderboard-container">
      <H5>Leaderboard</H5>
      <HTMLTable condensed striped>
        <thead>
          <tr>
            <td />
            <th>Rank</th>
            <th>Username</th>
            <th>Tasks completed</th>
          </tr>
        </thead>
        <tbody>
          {scores.map((score, index) => (
            <tr
              key={score.id}
              className={
                keycloak.keycloak.idTokenParsed &&
                (keycloak.keycloak.tokenParsed as any).preferred_username ===
                  score.username
                  ? `user-score`
                  : ''
              }
            >
              <td>
                {keycloak.keycloak.idTokenParsed &&
                  (keycloak.keycloak.tokenParsed as any).preferred_username ===
                    score.username && (
                    <Icon intent={Intent.PRIMARY} icon="user" />
                  )}
              </td>
              <td>{index + 1}</td>
              <td>{score.username}</td>
              <td>{score.tasksCount}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Card>
  );
};

export default Leaderboard;
