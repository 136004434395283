import { DefaultApi as AnnotatorApi } from '@wavely/annotator-sdk';

import axios from './axios';

const basePath =
  process.env.REACT_APP_ANNOTATOR_URL === 'localhost'
    ? undefined
    : `https://${process.env.REACT_APP_ANNOTATOR_URL}`;

const annotatorApiClient = new AnnotatorApi(undefined, basePath, axios);

export default annotatorApiClient;
